import React, {useEffect, useState, useMemo} from "react"
import {Card, CardBody, CardHeader, CardFooter} from "../../../../_metronic/_partials/controls";
import {MainCategory} from "./ProductCards/MainCategory";
import {CategoriesCard} from "./ProductCards/CategoriesCard";
import {ProductsList} from "./ProductCards/ProductsList";
import {fetchCategories, saveOrders, submitOrders} from "./ordersCrud";
import {useSelector} from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import {Select,
    ListSubheader,
    TextField,
    InputAdornment,
    IconButton,
    Collapse} from "@material-ui/core";
import FilledInput from "@material-ui/core/FilledInput";
import MenuItem from "@material-ui/core/MenuItem";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SearchIcon from "@material-ui/icons/Search";
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import { invalid } from "moment/moment";

export function SupplierInfo({supplier, setIsSupplerSelected, companies, setNewOrder}){

    console.log("Supplier: ", supplier)

    const inititalCategoryTree = {
        branches: 0,
        selectedCategories:[],
        categoryType:"",
        children:[]
    }

    const initialCategory = {
        supplierId: "",
        categories: [],
        type: ""
    }

    const [categoriesTree,setCategoriesTree] = useState(inititalCategoryTree)

    const [objectsArray,setObjectsArray] = useState([]);
    const [defaultCategory,setDefaultCategory] = useState("")

    const [open, setOpen] = React.useState(false);
    const [notApprovedOpen, setNotApprovedOpen] = useState(false);
    const [isCompanySelected, setIsCompanySelected] = useState(false);

    const [selectedOption, setSelectedOption] = useState();
    const [searchText, setSearchText] = useState("");
    const [isSubmitExpanded, setIsSubmitExpanded] = useState(false);

    const [currentOrders,setCurrentOrders] = useState([]);
    const [currOrderIndex, setCurrOrderIndex] = useState(null);
    const [defaultInvoiceDate, setDefaultInvoiceDate] = useState("");
    const [defaultShipDate, setDefaultShipDate] = useState("");

    const [alertTitle, setAlertTitle] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertText, setAlertText] = useState("");

    const handleAlertClose = () => {
        setAlertOpen(false);
        setAlertTitle("");
        setAlertText("");
    }

    const fireAlert = (title, text) => {
        setAlertTitle(title);
        setAlertText(text);
        setAlertOpen(true);
    }

    const containsText = (text, searchText) => {
        // console.log(companies)
        if(text){
            return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
        }
        
    }

    const displayedOptions = useMemo(() => {
        return companies.filter((company) => containsText(company.businessName, searchText) || containsText(company.sageCustomerId, searchText))
    },[searchText]);

    

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    function handleNotApprovedClickOpen() {
        setNotApprovedOpen(true);
    }

    function handleNotApprovedClose() {
        setNotApprovedOpen(false);
    }


    const {user,accessToken} = useSelector(
        ({auth}) => ({
            user: auth.user != null ? auth.user : null,
            accessToken: auth.authToken,
        }))
        // { companies, companyIdList } = useSelector(state => state.companyReducer);

    const initialCompanyValues = {
        _id: "",
        corporationName: "",
        businessName: "",
        deliveryAddress: "",
        deliveryCity: "",
        deliveryZipcode: "",
        businessPhoneNumber: "",
        businessFax: "",
        businessEmail: "",
        businessType: "",
        taxIdNumber: "",
        businessEstablishmentDate: "",
        placeOfBusinessDescription: ""
    }

    const [company,setCompany] = useState(initialCompanyValues)
    // const [companies,setCompanies] = useState([])
    const [locations,setLocations] = useState([])
    const [fullCategoryTree,setFullCategoryTree] = useState([])
    const [isDataFetched, setIsDataFetched] = useState(false)

    

    useEffect(() =>{

        fetchCategories(supplier._id,accessToken).then(response =>{
            console.log("categories fetched: ", response.data)
            setObjectsArray(response.data)
            setFullCategoryTree(response.data)
            setCategoriesTree({
                branches: 0,
                selectedCategories: [],
                categoryType: response.data.type,
                children:response.data
            })
            setDefaultCategory(response.data.type)
            setIsDataFetched(true)
        });

        if (companies.length == 1){
            setCompany(companies[0])
            setLocations(companies[0].locations)
            setIsCompanySelected(true)
        }

        // fetchCompanies(user.id,accessToken,supplier._id).then(response =>{
        //     console.log("Jahhahah response",response.data)
        //     
        //     setCompanies(response.data)
        //     // setCompany(response.data[0])
        //     // setLocations(response.data[0].locations)
        // })
    },[])

    const handleCompanyChange = (element) => {

        const clickedCompany = companies.find(company => company._id == element.target.value)
        const clickedCompanyLocations = clickedCompany.locations

        console.log("Companies from Supplier Info: ", companies)
        console.log("Clicked Company: ", clickedCompany)

        if(clickedCompanyLocations.length > 0){
            setCompany(clickedCompany)
            setLocations(clickedCompanyLocations)
            setIsCompanySelected(true)
        } else{
            handleNotApprovedClickOpen()
        }
        
    }
    
    const handleBack = () => {
        setNewOrder(false)
    }

    const handleProductAmountChange = (orderId, productId, productAmount) => {
        let orderIndex = currentOrders.findIndex(order => order._id === orderId)
        let order = currentOrders[orderIndex]
        let newOrders = [...currentOrders]

        let products = order.products
        let productIndex = products.findIndex(product => product._id === productId)

        const oldTotalAmount = order.totalProductQty
        const oldTotalCost = order.totalCost
        let newTotalAmount = oldTotalAmount
        let newTotalCost = parseFloat(oldTotalCost)

        const product = products[productIndex]
        let productPrice = (product.listPrice || product.listPrice == '') ? product.listPrice : product.price
        if(product.isCatchWeight){
            productPrice = productPrice * product.avgWeight
            productPrice = Math.round( parseFloat(productPrice) * 1e2 ) / 1e2
        }

        const oldProductAmount = product.amount;
        let newAmountParsed = parseFloat(productAmount)

        if(isNaN(newAmountParsed)){
            newAmountParsed = 0
        }

        if(productPrice > 0){
            newTotalCost -= parseFloat(productPrice) * oldProductAmount
            newTotalCost += parseFloat(productPrice) * newAmountParsed
        } else {
            newTotalCost -= parseFloat(productPrice) * oldProductAmount

        }

        newTotalAmount -= oldProductAmount
        newTotalAmount += newAmountParsed

        products[productIndex] = {
            ...products[productIndex],
            amount : newAmountParsed,
        }

        if(newTotalAmount == 0){
            newTotalCost = 0
        }

        const newOrder = {
            ...order,
            totalProductQty : newTotalAmount,
            totalCost : Math.round( parseFloat(newTotalCost) * 1e2 ) / 1e2,
            products : products
        }

        newOrders[orderIndex] = newOrder
        
        setCurrentOrders(newOrders)
    }

    const handlePriceChange = (orderId, productId, newPrice) => {
        let orderIndex = currentOrders.findIndex(order => order._id === orderId)
        let order = currentOrders[orderIndex]
        let newOrders = [...currentOrders]

        let products = order.products
        let productIndex = products.findIndex(product => product._id === productId)
        const product = products[productIndex]

        let newPriceParsed = parseFloat(newPrice)
        let newEstimatedPrice = 0
        let oldEstimatedPrice = 0

        const oldPrice = (product.listPrice || product.listPrice == '') ? product.listPrice : product.price
        const oldTotalCost = order.totalCost
        
        let newTotalCost = parseFloat(oldTotalCost)

        if(product.isCatchWeight){
            oldEstimatedPrice = oldPrice * product.avgWeight
            newEstimatedPrice = newPriceParsed * product.avgWeight

            oldEstimatedPrice = Math.round( parseFloat(oldEstimatedPrice) * 1e2 ) / 1e2
            newEstimatedPrice = Math.round( parseFloat(newEstimatedPrice) * 1e2 ) / 1e2
        }
        
        if(oldPrice == ''){
            newTotalCost -= parseFloat(product.amount * 0)
        } else{
            if(product.isCatchWeight){
                newTotalCost -= parseFloat(product.amount * oldEstimatedPrice)
            } else{
                newTotalCost -= parseFloat(product.amount * oldPrice)
            }
        }   

        if(!isNaN(newPriceParsed)){
            
            if(product.isCatchWeight){
                newTotalCost += parseFloat(product.amount * newEstimatedPrice)
            } else{
                newTotalCost += parseFloat(product.amount * newPriceParsed)
            }
        }  

        if(isNaN(newPriceParsed)){
            newPriceParsed = ""
        }

        if(product.listPrice || product.listPrice == ''){
            products[productIndex] = {
                ...products[productIndex],
                listPrice : newPriceParsed,
            }
        } else{
            products[productIndex] = {
                ...products[productIndex],
                price : newPriceParsed,
            }
        }

        if(product.isCatchWeight){
            products[productIndex] = {
                ...products[productIndex],
                estimatedPrice: newEstimatedPrice
            }
        }

        const newOrder = {
            ...order,
            totalCost : Math.round( parseFloat(newTotalCost) * 1e2 ) / 1e2,
            products : products
        }

        newOrders[orderIndex] = newOrder
        
        setCurrentOrders(newOrders)
    }

    function handleRemove(orderId, productId) {
        let orderIndex = currentOrders.findIndex(order => order._id === orderId)
        let order = currentOrders[orderIndex]
        let newOrders = [...currentOrders]

        const productToRemove = order.products.find(product => product._id == productId)
        const productAmount = productToRemove.amount
        let productPrice = (productToRemove.listPrice || productToRemove.listPrice == '') ? productToRemove.listPrice : productToRemove.price

        if(productPrice == ''){
            productPrice = 0
        }

        if(productToRemove.isCatchWeight){
            productPrice = productPrice * productToRemove.avgWeight
            productPrice = Math.round( parseFloat(productPrice) * 1e2 ) / 1e2
        }

        let newTotalCost = parseFloat(order.totalCost)
        newTotalCost -= productAmount * productPrice

        let newTotalAmount = order.totalProductQty
        newTotalAmount -= productAmount

        let products = order.products.filter(product => product._id !== productId)

        const newOrder = {
            ...order,
            totalProductQty : newTotalAmount,
            totalCost : Math.round( parseFloat(newTotalCost) * 1e2 ) / 1e2,
            products : products
        }
        
        if(products.length > 0){
            newOrders[orderIndex] = newOrder
        } else{
            newOrders = currentOrders.filter(currOrder => currOrder._id !== orderId)
        }
        
        setCurrentOrders([...newOrders])
    }

    const handleOrderCommentChange = (orderIndex, newComment) => {
        let newOrders = [...currentOrders]
        newOrders[orderIndex].comment = newComment
        
        setCurrentOrders(newOrders)
    }

    const handleOrderCustomerPoChange = (orderIndex, newCustomerPo) => {
        let newOrders = [...currentOrders]
        newOrders[orderIndex].customerPo = newCustomerPo
        
        setCurrentOrders(newOrders)
    }

    const handleOrderShipViaChange = (orderIndex, newShipVia) => {
        let newOrders = [...currentOrders]
        newOrders[orderIndex].shipVia = newShipVia
        
        setCurrentOrders(newOrders)
    }

    const handleDefaultInvoiceDateChange = (e) => {
        
        let newOrders = [...currentOrders]

        if(user.tenant == process.env.REACT_APP_WAHMEY_TENANT){
            for(let i = 0; i < newOrders.length; i++){
                newOrders[i].invoiceDate = e.target.value
                newOrders[i].shipDate = e.target.value
            }
            setDefaultInvoiceDate(e.target.value)
            setDefaultShipDate(e.target.value)

        } else{
            for(let i = 0; i < newOrders.length; i++){
                newOrders[i].invoiceDate = e.target.value
            }
            setDefaultInvoiceDate(e.target.value)
        }
        
        setCurrentOrders(newOrders)
    }

    const handleDefaultShipDateChange = (e) => {
        
        let newOrders = [...currentOrders]

        for(let i = 0; i < newOrders.length; i++){
            newOrders[i].shipDate = e.target.value
        }

        setDefaultShipDate(e.target.value)
        setCurrentOrders(newOrders)
    }

    const handleOrderInvoiceDateChange = (orderIndex, newInvoiceDate) => {
        let newOrders = [...currentOrders]
        newOrders[orderIndex].invoiceDate = newInvoiceDate

        if(user.tenant == process.env.REACT_APP_WAHMEY_TENANT){
            newOrders[orderIndex].shipDate = newInvoiceDate
        }
        
        setCurrentOrders(newOrders)
    }

    const handleOrderShipDateChange = (orderIndex, newShipDate) => {
        let newOrders = [...currentOrders]
        newOrders[orderIndex].shipDate = newShipDate

        if(user.tenant == process.env.REACT_APP_WAHMEY_TENANT){
            newOrders[orderIndex].invoiceDate = newShipDate
        }
        
        setCurrentOrders(newOrders)
    }

    const handleSubmitSingleOrder = async (orderIndex, isAdminOverride = false) => {
        console.log("Current index: ", currOrderIndex)

        let order = {...currentOrders[orderIndex]}
        let isOrdersValid = true
        let ordersToSubmit = []

        let orderProducts = []

        let invalidMessage = ""

        order.products.map(product => {
            
            if(product._id !== process.env.REACT_APP_FUEL_ID){
                if(product.amount > product.qtyOnHand){
                    isOrdersValid = false
                    invalidMessage += "Not enough items to fulfill order, except for fuel, amount to order must be less than QoH. "
                    // setCurrOrder(order)
                    setCurrOrderIndex(orderIndex)
                }
            }
            if(product.listPrice || product.listPrice === ''){
                if(product.listPrice === '' || product.listPrice < 0){
                    isOrdersValid = false
                    invalidMessage += "All unit price must be 0 or greater. "
                    // setCurrOrder(null)
                    setCurrOrderIndex(null)
                }
            } else{
                if(product.price === '' || product.price < 0){
                    isOrdersValid = false
                    invalidMessage += "All unit price must be 0 or greater. "
                    // setCurrOrder(null)
                    setCurrOrderIndex(null)
                }
            }

            if(product.amount > 0){
                orderProducts.push(product)
            }
        })

        if(order.invoiceDate && order.shipDate && order.invoiceDate !== '' && order.shipDate !== ''){

        } else{
            isOrdersValid = false
            invalidMessage += "To submit, order must have an invoice date and a ship date. "
            // setCurrOrder(null)
            setCurrOrderIndex(null)
        }

        if(orderProducts.length > 0){
            ordersToSubmit.push({
                ...order,
                status: "Submitted",
                products : orderProducts
            })
        } 

        // setIsOrdersValid(isOrdersValid)

        if(isOrdersValid){

            // console.log("Order to submit: ", ordersToSubmit[0])
            await submitOrders(user.id, ordersToSubmit, accessToken).then(response =>{
                
                let newCurrentOrders = [...currentOrders]
                newCurrentOrders.splice(orderIndex, 1)
                setCurrentOrders(newCurrentOrders)

                setCurrOrderIndex(null)
                fireAlert("Success!", `Order #${order.orderNumber} has been submitted!`)

                setCompany(initialCompanyValues)
                setLocations([])
                setIsCompanySelected(false)

                window.scrollTo({
                    top: 0,
                    behavior: "smooth" // Optional, smooth scrolling effect
                });
            }).catch(e=>{console.log(e)})
        } else{
            if(isAdminOverride){
                
                await submitOrders(user.id, ordersToSubmit, accessToken).then(response =>{

                    let newCurrentOrders = [...currentOrders]
                    newCurrentOrders.splice(orderIndex, 1)
                    setCurrentOrders(newCurrentOrders)

                    setCurrOrderIndex(null)
                    fireAlert("Success!", "Admin override! Some order was invalid but was submitted anyways. " + invalidMessage)

                    setCompany(initialCompanyValues)
                    setLocations([])
                    setIsCompanySelected(false)
                    
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth" // Optional, smooth scrolling effect
                      });
                }).catch(e=>{console.log(e)})
            } else{
            
                fireAlert("Warning!", invalidMessage)
                // handleClickOpen()
            }
        }
    }

    const handleSaveOrders = () => {
        let ordersToSubmit = []

        currentOrders.map((order, index) => {
            let orderProducts = []

            order.products.map(product => {
                if(product.amount > 0){
                    orderProducts.push(product)
                }
            })
            
            ordersToSubmit.push({
                orderId : order._id,
                products : orderProducts,
                comment: order.comment,
                shipDate: order.shipDate,
                invoiceDate: order.invoiceDate,
                customerPo : order.customerPo,
                shipVia : order.shipVia,
            })
        })
        
        console.log(ordersToSubmit)

        saveOrders(ordersToSubmit, accessToken).then(response =>{
            window.location.reload()
        }).catch(e=>{console.log(e)})
    }

    let componentType = <ProductsList filterCategory={{}} supplier={supplier} categories={fullCategoryTree} company={company} locations={locations} 
                                        currentOrders={currentOrders} setCurrentOrders={setCurrentOrders} defaultInvoiceDate={defaultInvoiceDate} defaultShipDate={defaultShipDate}
                                         />
    // if(categoriesTree.selectedCategories.length == 0){
    //     componentType = <MainCategory categories={objectsArray} categoriesTree={categoriesTree} setCategoriesTree={setCategoriesTree} setCategories={setObjectsArray} 
    //             company={company} handleClickOpen={handleClickOpen} handleClose={handleClose} />
    // }else if(categoriesTree.selectedCategories[categoriesTree.selectedCategories.length - 1].children.length > 0){
    //     componentType = <CategoriesCard  categories={objectsArray} categoriesTree={categoriesTree} setCategoriesTree={setCategoriesTree} setCategories={setObjectsArray}/>
    // }else{
    //     componentType = <ProductsList filterCategory={{}} supplier={supplier} categories={fullCategoryTree} company={company} locations={locations}/>
    // }

    console.log("Current orders: ", currentOrders)

    return (

        <>
            { isDataFetched ? (
            <>
                <div className="row mb-4 justify-content-between">
                    <div>
                        <span className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg mr-2 cursor-pointer"
                            onClick={() => {
                                setIsSupplerSelected(false);
                            } }
                        >Suppliers /</span>
                        <span className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg mr-2 cursor-pointer"
                            onClick={() => {
                                setCategoriesTree({
                                    branches: 0,
                                    selectedCategories: [],
                                    categoryType: defaultCategory,
                                    children: categoriesTree.children
                                });
                                setObjectsArray(categoriesTree.children);
                            } }
                        >{supplier.entityName} Home</span>

                        {categoriesTree.selectedCategories.map((category, index) => {

                            if (categoriesTree.branches === index + 1) {
                                return (
                                    <span key={category.name + index} className="text-dark font-weight-bolder mb-1 font-size-lg mr-2">/ {category.name}</span>
                                );
                            } else {
                                return (
                                    <span key={category.name + index} className=" text-dark text-hover-primary font-weight-bolder mb-1 font-size-lg mr-2 cursor-pointer"
                                        onClick={() => {
                                            setCategoriesTree({
                                                branches: index + 1,
                                                selectedCategories: categoriesTree.selectedCategories.slice(0, index + 1),
                                                categoryType: category.childType,
                                                children: categoriesTree.children
                                            });

                                            setObjectsArray(category.children);

                                            console.log(categoriesTree);
                                        } }
                                    > {category.name}</span>
                                );
                            }
                        })}
                    </div>
                </div>
                <button type="button" className="btn btn-primary mb-6" onClick={() => handleBack()} >Back</button>
                    <Card>
                        <CardHeader title={supplier.entityName}>

                        </CardHeader>
                        <CardBody>
                            {/* <div className="row">
                                <p>{supplier.description}</p>
                            </div> */}
                            <div className="row">
                                <p>To show items select customer first.</p>
                            </div>
                            <div className="row mb-4">
                                <div className="col-12">
                                    <FormControl variant="filled" style={{ width: "100%" }}>
                                        {
                                            user.isSeller ? 
                                                <InputLabel>Customer</InputLabel>
                                                :
                                                <InputLabel>Company</InputLabel>
                                        }
                                        <Select
                                            MenuProps={{ autoFocus: false }}
                                            value={company._id}
                                            style={{ minWidth: "200px" }}
                                            input={<FilledInput name="company" id="company"
                                                onChange={(element) => {
                                                    handleCompanyChange(element);
                                                    // setCompany(companies.find(company => company._id == element.target.value))
                                                    // setLocations(companies.find(company => company._id == element.target.value).locations)
                                                } }
                                                onClose={() => setSearchText("")}
                                                type="text"
                                                className="form-control form-control-solid h-auto" />}>

                                            <ListSubheader>
                                                <TextField
                                                    size="small"
                                                    // Autofocus on textfield
                                                    autoFocus
                                                    placeholder="Type to search..."
                                                    fullWidth
                                                    InputProps={{
                                                        startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                        )
                                                    }}
                                                    onChange={(e) => setSearchText(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key !== "Escape") {
                                                            // Prevents autoselecting item while typing (default Select behaviour)
                                                            e.stopPropagation();
                                                        }
                                                    }}
                                                />
                                            </ListSubheader> 
                                            {displayedOptions.map((companyItem,index) =>(
                                                <MenuItem key={companyItem._id} selected={index === 0 } value={companyItem._id}>
                                                    {companyItem.businessName + (companyItem.balance ? ` (Balance: $${companyItem.balance})` : "")}
                                                </MenuItem>
                                            ))

                                            }

                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="row">
                                <div className="mb-4 form-group fv-plugins-icon-container">
                                    <label htmlFor={"invoiceDate"}>Invoice Date</label>
                                    <Input
                                        type="date"
                                        className={`form-control form-control-solid h-auto py-5 px-6`}
                                        name="personalBirthDate"
                                        value={defaultInvoiceDate}
                                        onChange={(e) => handleDefaultInvoiceDateChange(e)}
                                    />
                                </div>
                                { user.tenant != process.env.REACT_APP_WAHMEY_TENANT ? 
                                    <div className="mb-4 ml-3 form-group fv-plugins-icon-container">
                                        <label htmlFor={"invoiceDate"}>Ship Date</label>
                                        <Input
                                            type="date"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="personalBirthDate"
                                            value={defaultShipDate}
                                            onChange={(e) => handleDefaultShipDateChange(e)}
                                        />
                                    </div>
                                    :
                                    null
                                }
                                
                            </div>
                        </CardBody>
                        {
                            isCompanySelected ?
                            componentType
                            :
                            null
                        }
                    </Card>
                    {
                        currentOrders.length > 0 ?
                            currentOrders.map((order, index) => 
                                <Card key={`currentOrders-${order.orderNumber}`}>
                                    <CardHeader title={`Order #${order?.orderNumber} | ${order?.companyName}`}>

                                    </CardHeader>
                                    <CardBody>
                                        <div className="text-dark-50 line-height-lg">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr className='border-bottom'>
                                                            <th className="pl-0 font-weight-bold text-muted text-uppercase">Ordered
                                                                Items
                                                            </th>
                                                            <th className="text-center font-weight-bold text-muted text-uppercase">Avg Weight</th>
                                                            <th className="text-right font-weight-bold text-muted text-uppercase">Unit
                                                                Price
                                                            </th>
                                                            <th className="text-center font-weight-bold text-muted text-uppercase">QoH</th>
                                                            <th className="text-center font-weight-bold text-muted text-uppercase">Qty To Order</th>
                                                            <th className="text-right font-weight-bold text-muted text-uppercase">Item
                                                                Price
                                                            </th>
                                                            <th className="text-right font-weight-bold text-muted text-uppercase">Total
                                                                Price
                                                            </th>
                                                            <th className="text-right pr-0 font-weight-bold text-muted text-uppercase">Remove</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {order.products.map(product => (
                                                        <tr className="font-weight-boldest border-bottom-0">
                                                            <td className="border-top-0 pl-0 py-2 d-flex align-items-center">
                                                                {
                                                                    product.imagePath && product.imagePath != '' ?
                                                                        <div className="symbol symbol-40 flex-shrink-0 mr-4 bg-light">
                                                                            <div className="symbol-label"
                                                                                style={{backgroundImage: "url(" + product.imagePath + ")"}}>
                                                                            </div>
                                                                        </div>
                                                                    :
                                                                        null
                                                                }
                                                                
                                                                <div>
                                                                    <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                                        {product.name}
                                                                    </span>
                                                                    <span className={'text-muted font-weight-bold'}>
                                                                        {product.sku}
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td className="border-top-0 text-center py-2 align-items-center">
                                                                {
                                                                    product.isCatchWeight ? 
                                                                        <div className="col-12 text-center">
                                                                            <span className="text-dark font-weight-bolder text-hover-primary font-size-lg">
                                                                                {product.avgWeight.toFixed(2)}
                                                                            </span>
                                                                        </div>
                                                                    :
                                                                        "N/A"
                                                                }
                                                            </td>
                                                            <td className="border-top-0 text-right py-2 align-middle" style={{minWidth:"125px"}}>{product.listPrice || product.listPrice == '' ? 
                                                                    
                                                                    <input
                                                                        value={product.listPrice}
                                                                        onChange={(e) => handlePriceChange(order._id, product._id, e.target.value)}
                                                                        className={`form-control form-control-solid h-auto py-2 px-6`}
                                                                        type="number"
                                                                        min="0"
                                                                    />
                                                                    :
                                                                    <input
                                                                        value={product.price}
                                                                        onChange={(e) => handlePriceChange(order._id, product._id, e.target.value)}
                                                                        className={`form-control form-control-solid h-auto py-2 px-6`}
                                                                        type="number"
                                                                        min="0"
                                                                    />
                                                                }
                                                            </td>
                                                            
                                                            <td className="border-top-0 text-center py-2 align-items-center">
                                                                {product.qtyOnHand}
                                                            </td>
                                                            <td className="border-top-0 text-center py-2 align-items-center" style={{minWidth:"125px"}}>
                                                                <input
                                                                    value={product.amount}
                                                                    onChange={(e) => handleProductAmountChange(order._id, product._id, e.target.value)}
                                                                    className={`form-control form-control-solid h-auto py-2 px-6`}
                                                                    type="number"
                                                                    min="0"
                                                                />
                                                            </td>
                                                            
                                                            <td className="border-top-0 text-center py-2 align-items-center">
                                                                {
                                                                    product.isCatchWeight ? 
                                                                        product.estimatedPrice  
                                                                    : 
                                                                        product.listPrice && product.listPrice !== '' ?
                                                                            product.listPrice
                                                                        :
                                                                            product.price
                                                                }
                                                            </td>
                                                            <td className="border-top-0 text-center py-2 align-items-center">
                                                                {
                                                                    product.isCatchWeight ? 
                                                                        product.estimatedPrice * product.amount
                                                                    : 
                                                                        product.listPrice && product.listPrice !== '' ?
                                                                            product.listPrice * product.amount
                                                                        :
                                                                            product.price * product.amount
                                                                }
                                                            </td>
                                                            <td className="border-top-0 text-right py-2 align-middle">
                                                                <InputAdornment position="end">
                                                                    <IconButton  onClick={() => handleRemove(order._id, product._id)}
                                                                    >
                                                                    <span  className="center svg-icon svg-icon-md svg-icon-primary">
                                                                        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                                                                    </span>
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            </td>
                                                            {/*<td className="text-primary border-top-0 pr-0 py-4 text-right align-middle">$449.00</td>*/}
                                                        </tr>
                                                    ))}
                                                        <tr className="font-weight-boldest border-bottom-0">
                                                            <td className="pl-0 py-4 d-flex align-items-center">Total</td>
                                                            <td ></td>
                                                            <td ></td>
                                                            <td className="text-center py-4 align-middle">{order.totalProductQty}</td>
                                                            <td ></td>
                                                            <td ></td>
                                                            <td className="text-right py-4 align-middle">{`$${order.totalCost}`}</td>

                                                        </tr>
                                                    {/*<tr className='border-top'>*/}
                                                    {/*    <td colSpan="2"></td>*/}
                                                    {/*    <td className="font-weight-bolder text-right">Subtotal</td>*/}
                                                    {/*    <td className="font-weight-bolder text-right pr-0">$1538.00</td>*/}
                                                    {/*</tr>*/}
                                                    {/*<tr>*/}
                                                    {/*    <td colSpan="2" className="border-0 pt-0"></td>*/}
                                                    {/*    <td className="border-0 pt-0 font-weight-bolder text-right">Delivery*/}
                                                    {/*        Fees*/}
                                                    {/*    </td>*/}
                                                    {/*    <td className="border-0 pt-0 font-weight-bolder text-right pr-0">$15.00</td>*/}
                                                    {/*</tr>*/}
                                                    {/*<tr>*/}
                                                    {/*    <td colSpan="2" className="border-0 pt-0"></td>*/}
                                                    {/*    <td className="border-0 pt-0 font-weight-bolder font-size-h5 text-right">Grand*/}
                                                    {/*        Total*/}
                                                    {/*    </td>*/}
                                                    {/*    <td className="border-0 pt-0 font-weight-bolder font-size-h5 text-success text-right pr-0">$1553.00</td>*/}
                                                    {/*</tr>*/}
                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                        
                                    </CardBody>
                                    <CardFooter>
                                        <div className="row justify-content-between mb-3">
                                            <div className="d-flex">
                                                {
                                                    isSubmitExpanded ?
                                                        <button className="btn btn-primary" onClick={() => setIsSubmitExpanded(oldIsSubmitExpanded => !oldIsSubmitExpanded)}>Close</button>
                                                        :
                                                        <button className="btn btn-primary" onClick={() => setIsSubmitExpanded(oldIsSubmitExpanded => !oldIsSubmitExpanded)}>Submit</button>
                                                }
                                            </div>
                                        </div>
                                        <Collapse in={isSubmitExpanded} timeout="auto" unmountOnExit>
                                            <div>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={6}>
                                                        <div className="form-group fv-plugins-icon-container">
                                                            <label htmlFor={"orderComent"}>Comment</label>
                                                            <textarea
                                                                value={order.comment}
                                                                onChange={(e) => handleOrderCommentChange(index, e.target.value)}
                                                                className={`form-control form-control-solid h-auto py-2 px-6`}
                                                                placeholder={"Comment"}
                                                                rows="3"
                                                            />
                                                        </div>
                                                        <div className="form-group fv-plugins-icon-container">
                                                            <label htmlFor={"customerPo"}>Customer PO</label>
                                                            <input
                                                                value={order.customerPo}
                                                                onChange={(e) => handleOrderCustomerPoChange(index, e.target.value)}
                                                                className={`form-control form-control-solid h-auto py-2 px-6`}
                                                                placeholder={"Customer PO"}
                                                                type="text"
                                                            />
                                                        </div>
                                                        <FormControl variant="filled" style={{ width: "100%" }}>
                                                            <InputLabel>Ship Via</InputLabel>
                                                            <Select
                                                                MenuProps={{ autoFocus: false }}
                                                                value={order.shipVia}
                                                                style={{ minWidth: "200px" }}
                                                                input={<FilledInput name="shipVia" id="shipVia"
                                                                    onChange={(e) => {
                                                                        handleOrderShipViaChange(index, e.target.value);
                                                                    } }
                                                                    type="text"
                                                                    className="form-control form-control-solid h-auto" />}>

                                                                <MenuItem selected key={`${order._id}-${company?.shipVia}`} value={company?.shipVia}>{company?.shipVia == "Our Truck" ? "Delivery" : company?.shipVia == "HOME" ? "Pick Up" : company?.shipVia}</MenuItem>
                                                                {
                                                                    supplier.shipViaOptions.map((option,index) =>(
                                                                        company?.shipVia == option ?
                                                                        null
                                                                        :
                                                                        <MenuItem key={option} value={option}>{option == "Our Truck" ? "Delivery" : option == "HOME" ? "Pick Up" : option}</MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div className="form-group fv-plugins-icon-container">
                                                            <label htmlFor={"invoiceDate"}>Invoice Date</label>
                                                            <Input
                                                                type="date"
                                                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                                                name="personalBirthDate"
                                                                value={order.invoiceDate}
                                                                onChange={(e) => handleOrderInvoiceDateChange(index, e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="form-group fv-plugins-icon-container">
                                                            <label htmlFor={"shipDate"}>Ship Date</label>
                                                            <Input
                                                                type="date"
                                                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                                                name="personalBirthDate"
                                                                value={order.shipDate}
                                                                onChange={(e) => handleOrderShipDateChange(index, e.target.value)}
                                                            />
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div className="row col-12 justify-content-center mt-5">
                                                <button type="button" className="btn btn-primary" onClick={() => handleSubmitSingleOrder(index)}>Submit</button>
                                            </div>
                                        </Collapse>
                                    </CardFooter>
                                </Card>
                            )
                        :
                            null
                    }
                    {
                        currentOrders.length > 0 ?
                            <div className="row py-8 px-8 py-md-27 px-md-0">
                                <div>
                                    <button type="button" className="btn btn-success font-weight-bolder text-uppercase px-9 py-4 ml-5"
                                            data-wizard-type="action-submit"
                                            onClick={() =>{
                                                handleSaveOrders();
                                            }}>Save All
                                    </button>
                                </div>
                            </div>
                        :
                            null
                    }
                    <div>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            {
                                user.tenant == process.env.REACT_APP_BIOWARE_TENANT ?
                                    <DialogTitle id="alert-dialog-title">{"Choose Customer"}</DialogTitle>
                                :
                                    <DialogTitle id="alert-dialog-title">{"Choose Company"}</DialogTitle>
                            }
                            <DialogContent>
                                {
                                    user.tenant == process.env.REACT_APP_BIOWARE_TENANT ?
                                        <DialogContentText id="alert-dialog-description">
                                            Please choose a customer to order to before moving forward.
                                            It can later be changed to purchase to various locations on the same order.
                                        </DialogContentText> 
                                    :
                                        <DialogContentText id="alert-dialog-description">
                                            Please choose a company to order to before moving forward.
                                            It can later be changed to purchase to various locations on the same order.
                                        </DialogContentText>
                                }
                                    
                                
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary" autoFocus>
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    <div>
                        <Dialog
                            open={notApprovedOpen}
                            onClose={handleNotApprovedClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Not Approved"}</DialogTitle>
                            <DialogContent>
                                {
                                    user.isSeller ?
                                        <DialogContentText id="alert-dialog-description">
                                            Customer locations have not been approved by this supplier.
                                        </DialogContentText>
                                    :
                                        <DialogContentText id="alert-dialog-description">
                                            Company locations have not been approved by this supplier.
                                        </DialogContentText>
                                }
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleNotApprovedClose} color="primary" autoFocus>
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    <div>
                        <Dialog
                            open={alertOpen}
                            onClose={handleAlertClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{alertTitle}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {alertText}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleAlertClose} color="primary" autoFocus>
                                    OK
                                </Button>
                                {
                                    user.isBuyerAdmin && currOrderIndex != null && currOrderIndex >= 0 ? 
                                    <Button onClick={() => handleSubmitSingleOrder(currOrderIndex, true)} color="primary" autoFocus>
                                        Force Submit
                                    </Button>
                                    :
                                    null
                                }
                            </DialogActions>
                        </Dialog>
                    </div>
                </>
            ) : null
            }
        </>
    )

}