import {
    Card,
    CardBody,
    CardHeader,
} from "../../../../_metronic/_partials/controls";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import React, {useEffect, useRef, useState} from "react";
import {Form, Formik} from "formik";
import {MyTextInput} from "../../Buyer/CustomFormInputs";
import Input from "@material-ui/core/Input";
// import ProductEditModal from "./ProductEditModal";
import {ProductsList} from "./ProductCards/ProductsList";
import {fetchCategories, patchOrderSummary, getOrderInfo, cancelOrder} from "./ordersCrud";
import {useSelector} from "react-redux";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

export default function OrderSummary({currOrder, setIsOderSummaryClicked, setIsOrderUpdated, isOrderUpdated}) {

    const {user,accessToken} = useSelector (
        ({auth}) =>({
            user: auth.user != null ? auth.user : null,
            accessToken: auth.authToken,
        })
    )
    const inititalCategoryTree = {
        branches: 0,
        selectedCategories:[],
        categoryType:"",
        children:[]
    }

    const [order,setOrder] = useState(currOrder)
    const [isCartUpdated,setIsCartUpdated] = useState(false)
    const [categoriesTree,setCategoriesTree] = useState(inititalCategoryTree)
    const [fullCategoryTree,setFullCategoryTree] = useState([])
    const [isCategoriesFetched,setIsCategoriesFetched] = useState(false)
    const [locations,setLocations] = useState(order.location)
    const [open, setOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
    const [cancelOpen, setCancelOpen] = useState(false);
    const [cancelDialogMessage, setCancelDialogMessage] = useState("");
    const formRef = useRef();
    const [isDateTodayFuture, setIsDateTodayFuture] = useState(false)

    useEffect(() => {
        getOrderInfo(accessToken,order._id).then(response =>{
            // Get new Item
            let newOrder = response.data
            let oldProducts = [...order.products]
            let oldProductIds = oldProducts.map(item => item._id)
            let newItem = newOrder.products.filter(item => !oldProductIds.includes(item._id))
            // Add old items with new item
            if(newItem.length > 0){
                oldProducts.push(newItem[0])
            }
            newOrder.products = oldProducts
            // Store order

            console.log("Order: ", newOrder)
            
            setOrder(newOrder)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isCartUpdated])

    const isDateInFuture = function(firstDate, secondDate) {
        if (firstDate.setHours(0, 0, 0, 0) >= secondDate.setHours(0, 0, 0, 0)) {
          return true;
        }
      
        return false;
      };

    useEffect(() =>{

        if(order.status !== 'Not Submitted'){
            var invoiceDate = new Date(order.invoiceDate.replace(/-/g, '\/'));
            var today = new Date();

            setIsDateTodayFuture(isDateInFuture(invoiceDate, today))
        }

        fetchCategories(order.supplier[0]._id,accessToken).then(response =>{
            // setObjectsArray(response.data)
            setFullCategoryTree(response.data)
            setCategoriesTree({
                branches: 0,
                selectedCategories: response.data.slice(0, 1),
                categoryType: response.data.type,
                children:response.data
            })
            // setDefaultCategory(response.data.type)
            setIsCategoriesFetched(true)
        });

    },[])

    const handleProductAmountChange = (productId, productAmount) => {
        let currOrder = {...order}

        let products = [...currOrder.products]
        let productIndex = products.findIndex(product => product._id === productId)

        products[productIndex] = {
            ...products[productIndex],
            amount : productAmount,
        }

        const newOrder = {
            ...currOrder,
            products : products
        }
        
        setOrder(newOrder)
    }

    const handlePriceChange = (productId, newPrice) => {
        let currOrder = {...order}

        let products = currOrder.products

        let productIndex = products.findIndex(product => product._id === productId)
        const product = products[productIndex]

        let newPriceParsed = parseFloat(newPrice)

        const oldPrice = (product.listPrice || product.listPrice == '') ? product.listPrice : product.price
        const oldTotalCost = currOrder.totalCost
        let newEstimatedPrice = 0
        let oldEstimatedPrice = 0

        if(product.isCatchWeight){
            oldEstimatedPrice = oldPrice * product.avgWeight
            newEstimatedPrice = newPriceParsed * product.avgWeight

            oldEstimatedPrice = Math.round( parseFloat(oldEstimatedPrice) * 1e2 ) / 1e2
            newEstimatedPrice = Math.round( parseFloat(newEstimatedPrice) * 1e2 ) / 1e2
        }
        
        let newTotalCost = parseFloat(oldTotalCost)
        
        if(oldPrice == ''){
            newTotalCost -= parseFloat(product.amount * 0)
        } else{
            if(product.isCatchWeight){
                newTotalCost -= parseFloat(product.amount * oldEstimatedPrice)
            } else{
                newTotalCost -= parseFloat(product.amount * oldPrice)
            }
        }   

        if(!isNaN(newPriceParsed)){
            if(product.isCatchWeight){
                newTotalCost += parseFloat(product.amount * newEstimatedPrice)
            } else{
                newTotalCost += parseFloat(product.amount * newPriceParsed)
            }
            
        }  

        if(isNaN(newPriceParsed)){
            newPriceParsed = ""
        }

        if(product.listPrice || product.listPrice == ''){
            products[productIndex] = {
                ...products[productIndex],
                listPrice : newPriceParsed,
            }
        } else{
            products[productIndex] = {
                ...products[productIndex],
                price : newPriceParsed,
            }
        }
        if(product.isCatchWeight){
            products[productIndex].estimatedPrice = newEstimatedPrice
        }

        const newOrder = {
            ...currOrder,
            totalCost : newTotalCost,
            products : products
        }

        setOrder(newOrder)
    }

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose(isRefresh = false) {
        setOpen(false);
        if(isRefresh){
            window.location.reload()
        }
    }

    function handleCancelClose() {
        setCancelOpen(false);
        
    }

    function handleRemove(productId) {
        let currOrder = {...order}

        let products = currOrder.products.filter(product => product._id !== productId)

        const newOrder = {
            ...currOrder,
            products : products
        }
        
        setOrder(newOrder)
    }

    function handleBack() {
        
        setIsOderSummaryClicked(false)
    }

    
    function handleBackOrderConversion() {
        
        const orderUpdate = {
            orderId: order._id,
            customerPo: order.customerPo,
            comment: order.comment,
            invoiceDate: order.invoiceDate,
            shipDate: order.shipDate,
            products: order.products,
            status: "Not Submitted"
        }

        patchOrderSummary(orderUpdate, accessToken).then(response => {
            setIsCartUpdated(!isCartUpdated)
            setDialogMessage("Order has been updated successfully.")
            handleClickOpen()
       }).catch(err => {
            console.log("Error updating order.")
            setDialogMessage("There was an error updating the order. Contact Allec team for further asistance.")
            handleClickOpen()
       })
    }

    function handleOrderCancelationClick() {
        setCancelDialogMessage("Are you sure you want to cancel order? Data may be lost.")
        setCancelOpen(true);
    }

    function handleOrderCancelation() {
        
        const orderUpdate = {
            orderId: order._id,
        }

        cancelOrder(orderUpdate, accessToken).then(response => {
            setDialogMessage("Order has been cancelled successfully.")
            handleClickOpen()
       }).catch(err => {
            console.log("Error cancelling order.")
            setDialogMessage("There was an error canceling the order. Contact Allec team for further asistance.")
            handleClickOpen()
       })
    }

    function toFixedCustom(num, fixed) {
        var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
        return num.toString().match(re)[0];
    }

    return(
        <>
            <button type="button" className="btn btn-primary mb-6" onClick={() => handleBack()} >Back</button>
            <Card>
                <CardHeader title={`Order #${order?.orderNumber} | ${order?.companyName}`}>

                </CardHeader>
                <CardBody>
                    <table className="table table-head-custom table-head-bg table-borderless table-vertical-center table-padding">
                        <thead>
                            <tr> 
                                <th>Product</th>
                                <th>SKU</th>
                                <th>Avg Weight</th>
                                <th>Unit Price</th>
                                <th>Product Qty.</th>
                                <th>Item Price</th>
                                <th>Total Amount</th>
                                { (order.status == "Not Submitted" || (isDateTodayFuture && (order.status == "Received")) || ((order.routeStatus != "Routing" && order.routeStatus !="Completed" && order.routeStatus !="Sent to WMS")&& user.tenant == process.env.REACT_APP_WAHMEY_TENANT)) ?
                                    <th>Remove</th>
                                    :
                                    null
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {order?.products?.map((product,index) =>  (
                                <tr>
                                    <td className="border-top-0 pl-0">
                                        { product.imagePath ? 
                                            <div className="symbol symbol-40 flex-shrink-0 mr-4 bg-light">
                                                <div className="symbol-label"
                                                    style={{backgroundImage: "url(" + product.imagePath + ")"}}></div>
                                            </div>
                                            :
                                            null
                                        }
                                        <span className="text-dark font-weight-bolder text-hover-primary font-size-lg">
                                            {product.description ? product.description : product.name}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="text-dark font-weight-bolder text-hover-primary font-size-lg">
                                            {product.sku}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="text-dark font-weight-bolder text-hover-primary font-size-lg">
                                            { 
                                                product.isCatchWeight ?
                                                    product.avgWeight
                                                :
                                                    "N/A"
                                            }
                                        </span>
                                    </td>
                                    <td className="border-top-0 text-right py-2 align-middle">{product.listPrice || product.listPrice == '' ?                  
                                            <input
                                                value={product.listPrice}
                                                onChange={(e) => handlePriceChange(product._id, e.target.value)}
                                                className={`form-control form-control-solid h-auto py-2 px-6`}
                                                type="number"
                                                min="0"
                                            />
                                            :
                                            <input
                                                value={product.price}
                                                onChange={(e) => handlePriceChange(product._id, e.target.value)}
                                                className={`form-control form-control-solid h-auto py-2 px-6`}
                                                type="number"
                                                min="0"
                                            />
                                        }
                                    </td>
                                    <td className="border-top-0 text-center py-2 align-items-center" style={{minWidth:"125px"}}>
                                        { (order.status == "Not Submitted" || (isDateTodayFuture && (order.status == "Received")) || ((order.routeStatus != "Routing" && order.routeStatus !="Completed" && order.routeStatus !="Sent to WMS") && user.tenant == process.env.REACT_APP_WAHMEY_TENANT)) ?
                                            <div className="align-middle">
                                                <button className="btn btn-xs btn-light-primary btn-icon mr-2" disabled={product.amount === 0} onClick={() => {
                                                    handleProductAmountChange(product._id, product.amount - 1)
                                                }}>
                                                    <i className="ki ki-minus icon-xs"></i>
                                                </button>
                                                <span className="mr-2 font-weight-bolder align-middle">{product.amount}</span>
                                                <button className="btn btn-xs btn-light-primary btn-icon mr-2" onClick={() => {
                                                    handleProductAmountChange(product._id, product.amount + 1)
                                                }} >
                                                    <i className="ki ki-plus icon-xs"></i>
                                                </button>
                                            </div>
                                            :
                                            <span className="mr-2 font-weight-bolder align-middle">{product.amount}</span>
                                        }
                                    </td>
                                    <td>
                                        <span className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                            {
                                                product.estimatedPrice ? 
                                                    <span className="label label-lg label-light-primary label-inline">${toFixedCustom((product.estimatedPrice), 2)}</span>
                                                :
                                                    product.listPrice?
                                                        <span className="label label-lg label-light-primary label-inline">${toFixedCustom((product.listPrice), 2)}</span>
                                                    :
                                                        <span>${toFixedCustom((product.price), 2)}</span>
                                            }
                                        </span>
                                    </td>
                                    
                                    <td>
                                        <span className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                            {
                                                product.estimatedPrice ? 
                                                    <span className="label label-lg label-light-primary label-inline">${toFixedCustom((product.estimatedPrice * product.amount), 2)}</span>
                                                :
                                                    product.listPrice?
                                                        <span className="label label-lg label-light-primary label-inline">${toFixedCustom((product.listPrice * product.amount), 2)}</span>
                                                    :
                                                        <span>${toFixedCustom((product.price * product.amount), 2)}</span>
                                            }
                                        </span>
                                        
                                    </td>
                                    { (order.status == "Not Submitted" || (isDateTodayFuture && (order.status == "Received")) || ((order.routeStatus != "Routing" && order.routeStatus !="Completed" && order.routeStatus !="Sent to WMS") && user.tenant == process.env.REACT_APP_WAHMEY_TENANT)) ?
                                        <td>
                                            <InputAdornment position="end">
                                                <IconButton  onClick={() => handleRemove(product._id)}
                                                >
                                                <span  className="center svg-icon svg-icon-md svg-icon-primary">
                                                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                                                </span>
                                                </IconButton>
                                            </InputAdornment>
                                        </td>
                                        :
                                        null
                                    }
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </CardBody>
            </Card>
            { (order.status == "Not Submitted" || (isDateTodayFuture && (order.status == "Received")) || ((order.routeStatus != "Routing" && order.routeStatus !="Completed" && order.routeStatus !="Sent to WMS") && user.tenant == process.env.REACT_APP_WAHMEY_TENANT)) ?
                    isCategoriesFetched  ? 
                        <Card>
                            <CardHeader title={`Order #${order?.orderNumber} | ${order?.companyName}`}>

                            </CardHeader>
                            <CardBody>
                                <div className="row">
                                    <p>{order.supplier[0].description}</p>
                                </div>
                            </CardBody>

                            <ProductsList filterCategory={categoriesTree.selectedCategories[categoriesTree.selectedCategories.length - 1]} supplier={order.supplier[0]} 
                                        categories={fullCategoryTree} company={order.company[0]} locations={locations}
                                        isOrderSummary={true} setIsCartUpdated={setIsCartUpdated} isCartUpdated={isCartUpdated} 
                                        orderId={order._id} order={order}/>
                        </Card>
                    
                    :
                        <div className="row justify-content-center">
                            <span className="spinner spinner-primary spinner-lg"></span>
                        </div>
                :
                null
            }
            <Card>
                
                <CardHeader title={`Order #${order?.orderNumber} Details`}>
                    
                </CardHeader>
                <CardBody>
                    <Formik
                        initialValues={order}
                        innerRef={formRef}
                        onSubmit={(values)=>{
                            const orderUpdate = {
                                orderId: values._id,
                                customerPo: values.customerPo,
                                comment: values.comment,
                                invoiceDate: values.invoiceDate,
                                shipDate: values.shipDate,
                                products: order.products,
                                status: values.status
                            }

                            patchOrderSummary(orderUpdate, accessToken).then(response => {
                                setIsCartUpdated(!isCartUpdated)
                                setIsOrderUpdated(!isOrderUpdated)
                                setDialogMessage("Order has been updated successfully.")
                                handleClickOpen()
                           }).catch(err => {
                                console.log("Error updating order.")
                                setDialogMessage("There was an error updating order. Contact Allec team for further asistance.")
                                handleClickOpen()
                           })
                        }}
                    >
                        {(props) => (
                            <Form>
                                <div>
                                    <div className="row align-self-center">
                                        <div className="col-md-6">
                                            <MyTextInput
                                                label="Company"
                                                name="companyName"
                                                type="text"
                                                placeholder=""
                                                value={order?.companyName}
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Location"
                                                name="locationAddressCity"
                                                type="text"
                                                placeholder=""
                                                value={order?.locationAddressCity}
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Supplier"
                                                name="supplierName"
                                                type="text"
                                                placeholder=""
                                                value={order?.supplierName}
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Total Product"
                                                name="totalProductQty"
                                                type="number"
                                                placeholder="###"
                                                value={order?.totalProductQty}
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Total Cost"
                                                name="totalCost"
                                                type="text"
                                                placeholder="$##.##"
                                                value={order?.totalCost ? `$${order?.totalCost}` : order?.totalCost}
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Status"
                                                name="status"
                                                type="text"
                                                placeholder="###"
                                                value={order?.status}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <MyTextInput
                                                label="Customer PO"
                                                name="customerPo"
                                                type="text"
                                                placeholder=""
                                                value={props.values.customerPo}
                                            />
                                            <MyTextInput
                                                label="Comment"
                                                name="comment"
                                                type="text"
                                                placeholder="###"
                                                value={props.values.comment}
                                            />
                                            { (order.status == "Not Submitted" || (isDateTodayFuture && (order.status == "Received"))  || ((order.routeStatus != "Routing" && order.routeStatus !="Completed" && order.routeStatus !="Sent to WMS") && user.tenant == process.env.REACT_APP_WAHMEY_TENANT)) ?
                                                null
                                                :
                                                <MyTextInput
                                                    label="Ship via"
                                                    name="shipVia"
                                                    type="text"
                                                    placeholder="###"
                                                    value={order?.shipVia}
                                                    disabled
                                                />
                                            }
                                            <div className="form-group fv-plugins-icon-container">
                                                <label htmlFor={"invoiceDate"}>Invoice Date</label>
                                                <Input
                                                    type="date"
                                                    className={`form-control form-control-solid h-auto py-5 px-6`}
                                                    name="invoiceDate"
                                                    onChange={props.handleChange}
                                                    value={props.values.invoiceDate}
                                                />
                                            </div>
                                            <div className="form-group fv-plugins-icon-container">
                                                <label htmlFor={"shipDate"}>Ship Date</label>
                                                <Input
                                                    type="date"
                                                    className={`form-control form-control-solid h-auto py-5 px-6`}
                                                    name="shipDate"
                                                    onChange={props.handleChange}
                                                    value={props.values.shipDate}
                                                />
                                            </div>
                                            {(typeof(props.values.submissionDate) !== 'undefined' && props.values.submissionDate !== '') ?
                                                <div className="form-group fv-plugins-icon-container">
                                                    <label htmlFor={"submissionDate"}>Submission Date</label>
                                                    <Input
                                                        type="date"
                                                        className={`form-control form-control-solid h-auto py-5 px-6`}
                                                        name="submissionDate"
                                                        onChange={props.handleChange}
                                                        disabled
                                                        value={props.values.submissionDate}
                                                    />
                                                </div>
                                                :
                                                null
                                            }
                                            {typeof(props.values.processedDate) !== 'undefined' ?
                                                <div className="form-group fv-plugins-icon-container">
                                                    <label htmlFor={"processedDate"}>Processed Date</label>
                                                    <Input
                                                        type="date"
                                                        className={`form-control form-control-solid h-auto py-5 px-6`}
                                                        name="processedDate"
                                                        onChange={props.handleChange}
                                                        disabled
                                                        value={props.values.processedDate}
                                                    />
                                                </div>
                                                :
                                                null
                                            }
                                            {typeof(props.values.inTransitDate) !== 'undefined' ?
                                                <div className="form-group fv-plugins-icon-container">
                                                    <label htmlFor={"inTransitDate"}>In Transit Date</label>
                                                    <Input
                                                        type="date"
                                                        className={`form-control form-control-solid h-auto py-5 px-6`}
                                                        name="inTransitDate"
                                                        onChange={props.handleChange}
                                                        disabled
                                                        value={props.values.inTransitDate}
                                                    />
                                                </div>
                                                :
                                                null
                                            }
                                            {typeof(props.values.deliveredDate) !== 'undefined' ?
                                                <div className="form-group fv-plugins-icon-container">
                                                    <label htmlFor={"deliveredDate"}>Delivered Date</label>
                                                    <Input
                                                        type="date"
                                                        className={`form-control form-control-solid h-auto py-5 px-6`}
                                                        name="deliveredDate"
                                                        onChange={props.handleChange}
                                                        disabled
                                                        value={props.values.deliveredDate}
                                                    />
                                                </div>
                                                :
                                                null
                                            }
                                        </div>

                                    </div>
                                </div>
                                <div className="row col-12 justify-content-center">
                                    <div className="row col-6">
                                        <button type="button" className="btn btn-primary" onClick={() => handleOrderCancelationClick()} >Cancel Order</button>
                                    </div>
                                    <div className="col-6 justify-content-end d-flex">
                                        {/* { (order.status == "Not Submitted" || (isDateTodayFuture && order.status == "Received")  || ((order.routeStatus != "Routing" && order.routeStatus !="Completed" && order.routeStatus !="Sent to WMS") && user.tenant == process.env.REACT_APP_WAHMEY_TENANT)) ?
                                            
                                            <button type="submit" className="btn btn-primary" >Update Order</button>
                                            
                                            :
                                            null
                                        } */}
                                        { order.status == "Not Submitted" || (isDateTodayFuture && (order.status == "Received")) || (order.routeStatus != 'Completed' && order.routeStatus != 'Routing' && user.tenant == process.env.REACT_APP_WAHMEY_TENANT)?
                                            
                                            <button type="submit" className="btn btn-primary" >Update Order</button>
                                            
                                            :
                                            null
                                        }
                                        { (order.status == "Back Order") ?
                                            <button type="button" className="btn btn-primary" onClick={() => handleBackOrderConversion()} >Convert to Order</button>
                                            
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </Form>
                        )}
                        
                    </Formik>
                </CardBody>
            </Card>

            <Card>
                <CardHeader title={`Order Updates`}>

                </CardHeader>
                <CardBody>
                    <table className="table table-head-custom table-head-bg table-borderless table-vertical-center table-padding">
                        <thead>
                            <tr> 
                                <th>Action</th>
                                <th>Product</th>
                                <th>SKU</th>
                                <th>Previous Price</th>
                                <th>New Price</th>
                                <th>Previous Qty.</th>
                                <th>New Qty.</th>
                                <th>Update Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {order?.itemUpdates?.map((update) =>  (
                                <tr>
                                    <td className="border-top-0 pl-0">
                                        <span className="text-dark font-weight-bolder text-hover-primary font-size-lg">
                                            {update.operation}
                                        </span>
                                    </td>
                                    <td className="border-top-0 pl-0">
                                        <span className="text-dark font-weight-bolder text-hover-primary font-size-lg">
                                            Product Name
                                        </span>
                                    </td>
                                    <td>
                                        <span className="text-dark font-weight-bolder text-hover-primary font-size-lg">
                                            {update.sku}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="text-dark font-weight-bolder text-hover-primary font-size-lg">
                                            {   update.operation == 'Updated' ?
                                                    update.previousPrice.toFixed(2)
                                                :
                                                update.operation == 'Added' ?
                                                    "N / A"
                                                :
                                                    update.price
                                            }
                                        </span>
                                    </td>
                                    <td className="border-top-0 text-right py-2 align-middle">
                                        <span className="text-dark font-weight-bolder text-hover-primary font-size-lg">
                                            {   update.operation == 'Updated' || update.operation == 'Added' ?
                                                    update.newPrice.toFixed(2)
                                                :
                                                    "N / A"
                                            }
                                        </span>
                                    </td>
                                    <td className="border-top-0 text-center py-2 align-items-center" style={{minWidth:"125px"}}>
                                        <span className="text-dark font-weight-bolder text-hover-primary font-size-lg">
                                            {   update.operation == 'Updated' ?
                                                    update.previousQuantity
                                                :
                                                update.operation == 'Added' ?
                                                    "N / A"
                                                :
                                                    update.quantity
                                            }
                                        </span>
                                    </td>
                                    <td>
                                        <span className="text-dark font-weight-bolder text-hover-primary font-size-lg">
                                            {   update.operation == 'Updated' || update.operation == 'Added' ?
                                                    update.newQuantity
                                                :
                                                    "N / A"
                                            }
                                        </span>
                                    </td>
                                    
                                    <td>
                                        <span className="text-dark font-weight-bolder text-hover-primary font-size-lg">
                                            { 
                                                new Date(update.updatedAt).toLocaleDateString('en-US', {
                                                    year: 'numeric',
                                                    month: 'long',
                                                    day: 'numeric',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    second: '2-digit',
                                                })
                                            
                                            }
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </CardBody>
            </Card>
            
            {/* <ProductEditModal
                product={editProduct}
                setProduct={setEditProduct}
                showModal={showProductModal}
                setShowModal={setShowProductModal}
                locations={locations}
                order={order}
                setOrder={setOrder}
            /> */}

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Order Confirmation"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {dialogMessage}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => handleClose(true)} color="primary" autoFocus>
                    OK
                </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={cancelOpen}
                onClose={handleCancelClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Order Confirmation"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {cancelDialogMessage}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCancelClose} color="primary" autoFocus>
                    No
                </Button>
                <Button onClick={handleOrderCancelation} color="primary" autoFocus>
                    Yes
                </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}